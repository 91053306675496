import { Pipe, PipeTransform } from '@angular/core';
import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';
import { differentlyStyledFirstNumber } from '../functions/nth-number.function';


@Pipe({
  name: 'differentlyStyledFirstNumber'
})
export class DifferentlyStyledFirstNumberPipe implements PipeTransform {

  transform(value: string, numberTriple?: KurzDifferentlyStyledValueObject, remainderTriple?: KurzDifferentlyStyledValueObject): KurzDifferentlyStyledValueObject[] {
    return differentlyStyledFirstNumber(value, numberTriple, remainderTriple);
  }

}
