/* eslint-disable @typescript-eslint/no-magic-numbers */

import { KurzDifferentlyStyledValueObject } from '../../kurz-differently-styled-value.types';


export function differentlyStyledFirstNumber(str: string, numberTriple?: KurzDifferentlyStyledValueObject, remainderTriple?: KurzDifferentlyStyledValueObject): KurzDifferentlyStyledValueObject[] {
  return differentlyStyledNthNumbers(str, [0], numberTriple, remainderTriple);
}

export function differentlyStyledLastNumber(str: string, numberTriple?: KurzDifferentlyStyledValueObject, remainderTriple?: KurzDifferentlyStyledValueObject): KurzDifferentlyStyledValueObject[] {
  return differentlyStyledNthNumbers(str, [-1], numberTriple, remainderTriple);
}

export function differentlyStyledNthNumbers(str: string, styledIndices: number[], numberTriple?: KurzDifferentlyStyledValueObject, remainderTriple?: KurzDifferentlyStyledValueObject): KurzDifferentlyStyledValueObject[] {

  //#region - making sure that out triples have values or their default values
  numberTriple = numberTriple || [null, 1, {}];
  remainderTriple = remainderTriple || [null, 0.6, {}];
  numberTriple = [null, (numberTriple[1] ?? 1), numberTriple[2] || {}];
  remainderTriple = [null, (remainderTriple[1] ?? 1), remainderTriple[2] || {}];
  //#endregion

  // in French "\u202F" (Narrow no-break space) is the decimal separator like the dot in 'de', 'nl' or the comma in 'en'
  // https://en.wikipedia.org/wiki/Decimal_separator
  // https://en.wikipedia.org/wiki/Whitespace_character
  const matches = str.match(/[\D]*[\d,\.\u2000-\u202F]*[\D]*/g)?.filter(_ => !!_);

  // if there is no number in "str" then "matches?.length" but this doesn't matter
  // because the execution in the forEach loop won't find a number and it won't effect the outcome
  const amountOfNumbers = matches?.length || 0;

  // all given negative indices are calculated to their referencing positive index now that we know the "length of the array"
  // so that -1 means the last element, -2 means the second last and so on
  styledIndices = styledIndices.map(num => {
    if (num < 0) {
      num = amountOfNumbers + num;
    }
    return num;
  });

  const returnArray: KurzDifferentlyStyledValueObject[] = [];

  matches?.forEach((str, i) => {

    // dividing the match in -text before the number, -the number itself and -text after the number
    const exec = /([\D]*)([\d,\.\u2000-\u202F]*[\d]+)([\D]*)/.exec(str);
    const before = exec?.[1];
    const number = exec?.[2];
    const after = exec?.[3];

    if (before) {
      returnArray.push([before, remainderTriple[1], remainderTriple[2]]);
    }

    if (number) {
      // if the number is supposed to be styled
      if (styledIndices.includes(i)) {
        returnArray.push([number, numberTriple[1], numberTriple[2]]);
      } else {
        returnArray.push([number, remainderTriple[1], remainderTriple[2]]);
      }

    }

    if (after) {
      returnArray.push([after, remainderTriple[1], remainderTriple[2]]);
    }

  });

  return returnArray;

}
