import { parseHostBindings } from '@angular/compiler';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { coerceBoolean } from '@util/functions/objects';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { KurzDifferentlyStyledValueObject } from './kurz-differently-styled-value.types';



@Component({
  selector: 'app-kurz-differently-styled-value',
  templateUrl: './kurz-differently-styled-value.component.html',
  styleUrls: ['./kurz-differently-styled-value.component.scss']
})
export class KurzDifferentlyStyledValueComponent implements OnInit, OnDestroy {

  @HostBinding('class')
  get clazzArr(): string[] {
    return [(this.flexFlow ? 'flex-flow' : 'inline-elements')];
  }

  private _valueObjects: KurzDifferentlyStyledValueObject[] = [];
  private _flexFlow: boolean;

  get valueObjects(): KurzDifferentlyStyledValueObject[] {
    return this._valueObjects;
  }

  get flexFlow(): boolean {
    return this._flexFlow;
  }

  @Input()
  set flexFlow(value: boolean) {
    this._flexFlow = coerceBoolean(value);
  }

  @Input()
  set valueObjects(value: KurzDifferentlyStyledValueObject[]) {
    value?.forEach(p => {
      p[0] = p[0] || '';
      p[1] = p[1] ?? 1;
      p[2] = p[2] || {};
      p[3] = p[3] || null;
    });
    this._valueObjects = value;
  }

  get value(): string {
    return this._valueObjects?.map(p => p[0]).join('');
  }

  @Input()
  set value(value: string) {
    this.valueObjects = [[(value || ''), 1, {}]];
  }

  @Input()
  set size(value: string) {
    const prop = '--kurz-differently-styled-value-font-size';
    switch (value) {
      case 'EXTRA_SMALL': this.utilCustomCSSPropertyService.setValue(prop, '12px', this.elementRef?.nativeElement); break;
      case 'SMALL': this.utilCustomCSSPropertyService.setValue(prop, '14px', this.elementRef?.nativeElement); break;
      case 'MEDIUM': this.utilCustomCSSPropertyService.setValue(prop, '16px', this.elementRef?.nativeElement); break;
      case 'LARGE': this.utilCustomCSSPropertyService.setValue(prop, '18px', this.elementRef?.nativeElement); break;
      case 'EXTRA_LARGE': this.utilCustomCSSPropertyService.setValue(prop, '20px', this.elementRef?.nativeElement); break;
      default: console.warn('Was not able to set size of the component with', value); break;
    }
  }


  constructor(private elementRef: ElementRef, private readonly utilCustomCSSPropertyService: UtilCustomCSSPropertyService) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  getStyleOfTriple(obj: KurzDifferentlyStyledValueObject) {
    const start = this.flexFlow ? {} : {'display':'inline', 'white-space': 'pre'};
    return { ...start, 'font-size': (obj[1] + 'em'), ...(obj[2])};
  }

  activateObject(e: UIEvent, obj: KurzDifferentlyStyledValueObject) {
    if (obj?.[3] && obj[3].actionType === 'activateElement') {
      obj[3]?.action?.(e, obj);
    }
  }

}
